import React, { useState, useEffect } from 'react';

import './ScreenImages.css';
import portrait1 from '../../assets/images/AndroidPortrait1.png';
import portrait2 from '../../assets/images/AndroidPortrait2.png';
import portrait3 from '../../assets/images/AndroidPortrait3.png';
import portrait4 from '../../assets/images/AndroidPortrait4.png';
import portrait5 from '../../assets/images/AndroidPortrait5.png';
import portrait6 from '../../assets/images/AndroidPortrait6.png';
import landscape1 from '../../assets/images/AndroidLandscape1.png';
import landscape2 from '../../assets/images/AndroidLandscape2.png';
import landscape3 from '../../assets/images/AndroidLandscape3.png';
import landscape4 from '../../assets/images/AndroidLandscape4.png';
import landscape5 from '../../assets/images/AndroidLandscape5.png';
import landscape6 from '../../assets/images/AndroidLandscape6.png';

const ScreenImages = (props) => {
  const [element, setElement] = useState(0);

  const portraitArray = [
    portrait1,
    portrait2,
    portrait3,
    portrait4,
    portrait5,
    portrait6,
  ];

  const landscapeArray = [
    landscape1,
    landscape2,
    landscape3,
    landscape4,
    landscape5,
    landscape6,
  ];

  let imageArray = [];

  // I'm borrowing the MDN doc notation here: "mql" stands for "media query list".
  const mql = window.matchMedia('(min-width: 46.875em)');

  // For first render
  let mobileView = mql.matches;
  if (mobileView) {
    imageArray = [...portraitArray];
  } else {
    imageArray = [...landscapeArray];
  }

  // For subsequent renders if screen size changes
  mql.addEventListener('change', (e) => {
    let mobileView = e.matches;
    if (mobileView) {
      imageArray = [...portraitArray];
    } else {
      imageArray = [...landscapeArray];
    }
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setElement((element) =>
        element > imageArray.length - 2 ? 0 : element + 1
      );
    }, 3000);
    return () => clearInterval(interval);
  }, [imageArray.length]);

  return (
    <div className='ScreenImages'>
      <img src={imageArray[element]} alt='Makeup Mate App' />
    </div>
  );
};

export default ScreenImages;
