import React from 'react';

import './AppInfo.css';

const AppInfo = () => {
  return (
    <div className='AppInfo'>
      <h2>Be alerted when your makeup is about to expire.</h2>
      <h3>Product Description</h3>
      <p>
        Do you remember your favorite tube of lipstick from high school? Do you
        still have that same tube of lipstick today? Should you?
      </p>
      <p>
        Although cosmetic manufacturers are not required to put expiration dates
        on their products, the FDA warns that cosmetics can degrade and break
        down over time due to microorganisms, such as bacteria and fungi.
        Exposure to these microorganisms can lead to eye and staph infections.
      </p>
      <p>
        Makeup Mate is here to help. By entering the name of a cosmetic, Makeup
        Mate will provide you with a expiration date based on industry
        recommendations. Makeup Mate also gives you the ability to create a
        reminder in your calendar for when your makeup will expire.
      </p>
      <h3>Product Features</h3>
      <ul>
        <li>Determine cosmetic type using cosmetic name</li>
        <li>Provide recommended expiration date based on cosmetic type</li>
        <li>Select purchase/first use date</li>
        <li>Set reminder for cosmetic expiration</li>
        <li>Save inventory of your cosmetics in your cosmetic catalog</li>
      </ul>
    </div>
  );
};

export default AppInfo;
