import React from 'react';

const AppleAppStoreLink = (props) => {
  return (
    <a href='https://apps.apple.com/us/app/makeup-mate/id1553563005?itsct=apps_box&amp;itscg=30200'>
      <img
        className=''
        src='https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-US?size=250x83&amp;releaseDate=1615075200&h=d69aad7ac27eee6ca551042a7a6c3c40'
        alt='Download on the App Store'
        width='200'
        height='50'
      />
    </a>
  );
};

export default AppleAppStoreLink;
