import React from 'react';

import './Main.css';
import AppInfo from '../AppInfo/AppInfo';
import ScreenImages from '../ScreenImages/ScreenImages';
import Aux from '../../hoc/Aux';

const Main = (props) => {
  return (
    <Aux>
      <div className='Main'>
        <AppInfo />
        <ScreenImages />
      </div>
    </Aux>
  );
};

export default Main;
