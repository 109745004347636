import React, { Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Layout from './components/Layout/Layout';
import Main from './components/Main/Main';

const Eula = React.lazy(() => {
  return import('./components/Eula/Eula');
});

const Privacy = React.lazy(() => {
  return import('./components/Privacy/Privacy');
});

const App = (props) => {
  const routes = (
    <Switch>
      <Route path='/eula' render={(props) => <Eula {...props} />} />
      <Route path='/privacy' render={(props) => <Privacy {...props} />} />
      <Route path='/' exact component={Main} />
      <Redirect to='/' />
    </Switch>
  );

  return (
    <Layout>
      <Suspense fallback={<p>Loading...</p>}>{routes}</Suspense>
    </Layout>
  );
};

export default App;
