import React from 'react';

const GooglePlayStoreLink = (props) => {
  return (
    <a href='https://play.google.com/store/apps/details?id=com.triplej.makeupmate&amp;pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
      <img
        className=''
        src='https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png'
        alt='Get it on Google Play'
        width='186'
        height='72'
      />
    </a>
  );
};

export default GooglePlayStoreLink;
