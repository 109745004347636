import React from 'react';

import './Toolbar.css';

const Toolbar = (props) => {
  return (
    <div className='Toolbar'>
      <nav>
        <ul>
          <li>
            <a href='/'>Home</a>
          </li>
          <li>
            <a href='/eula'>EULA</a>
          </li>
          <li>
            <a href='/privacy'>Privacy</a>
          </li>
          <li>
            <a href={'mailto:triplej7701@gmail.com'}>Contact Us</a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Toolbar;
