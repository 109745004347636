import React from 'react';

import './Layout.css';
import Background from '../UI/Background/Background';
import Header from '../Header/Header';
import Toolbar from '../Toolbar/Toolbar';
import AppleAppStoreLink from '../AppleAppStoreLink/AppleAppStoreLink';
import GooglePlayStoreLink from '../GooglePlayStoreLink/GooglePlayStoreLink';

const Layout = (props) => {
  return (
    <Background>
      <header>
        <Header />
      </header>
      <main className='Layout'>{props.children}</main>
      <footer>
        <div className='AppLinkFooter'>
          <AppleAppStoreLink />
          <GooglePlayStoreLink />
        </div>
        <Toolbar />
        <div className='CompanyName'>Triple J Technologies</div>
      </footer>
    </Background>
  );
};

export default Layout;
