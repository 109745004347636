import React from 'react';

import './Header.css';
import makeupMateHeader from '../../assets/images/header.png';
import AppleAppStoreLink from '../AppleAppStoreLink/AppleAppStoreLink';
import GooglePlayStoreLink from '../GooglePlayStoreLink/GooglePlayStoreLink';

const Header = (props) => {
  return (
    <div className='Header'>
      <div className='Image'>
        <a href='/'>
          <img src={makeupMateHeader} alt='Makeup Mate' />
        </a>
      </div>
      <div className='AppLinkHeader'>
        <AppleAppStoreLink />
        <GooglePlayStoreLink />
      </div>
    </div>
  );
};

export default Header;
